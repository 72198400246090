<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>{{ $route.meta.title }}</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<div id="container" class="ion-text-center">
				<h4>Área Restrita</h4>
				<h3>Solicitação de Fretes</h3>

				<ion-list>
					<ion-item-divider>
						<ion-label>Meu Dados</ion-label>
					</ion-item-divider>

					<ion-item lines="none">
						<ion-label class="ion-text-wrap">Deseja atualizar sua senha?</ion-label>
						<ion-toggle
							@click="changePassword = !changePassword"
							:checked="changePassword"
							:key="changePassword"
							color="primary"
						></ion-toggle>
					</ion-item>

					<template v-if="changePassword">
						<ion-item>
							<ion-label position="stacked">Nova Senha</ion-label>
							<ion-input
								v-model="newPassword"
								type="password"
							></ion-input>
						</ion-item>

						<ion-button v-if="updatingPassword" color="primary" expand="full" disabled>
							<ion-spinner name="crescent"></ion-spinner>
						</ion-button>

						<ion-button v-else color="primary" expand="full" @click="updatePassword" style="margin-top: 10px">
							Atualizar&nbsp;<ion-icon name="save-outline"></ion-icon>
						</ion-button>
					</template>
				</ion-list>

				<div v-if="$store.state.usuario.permissions['sync_customers']">
					<ion-list>
						<ion-item-divider>
							<ion-label>Sincronização de Clientes</ion-label>
						</ion-item-divider>

						<file-upload
							ref="upload"
							v-model="files"
							:multiple="false"
							@input-file="inputFile"
							@input-filter="inputFilter"
							:extensions="['csv']"
						>
							<ion-button
								color="primary"

								style="margin-top: 10px"
								@click.prevent="upload.active = true"
								:disabled="sincronizandoClientes"
							>
								Carregar arquivo .csv&nbsp;
								<ion-spinner v-if="sincronizandoClientes" name="crescent"></ion-spinner>
								<ion-icon v-else name="cloud-upload-outline"></ion-icon>
							</ion-button>
						</file-upload>
					</ion-list>

					<ion-note :key="index" v-for="(file,index) in files">{{file.name}}</ion-note><br><br>
					<ion-note v-html="msgSync"/>
				</div>

				<div id="tip" class="ion-text-end">
					<ion-note>Incluir solicitação <font-awesome-icon :icon="['far','hand-point-right']"/></ion-note>
				</div>
			</div>

			<ion-fab v-if="$store.state.usuario.permissions['create_requests_for_anyone'] || $store.state.usuario.permissions['create_requests_for_yourself']" vertical="bottom" horizontal="end" slot="fixed">
				<ion-fab-button @click="$router.push({name:'solicitacoes.create'})">
					<ion-icon name="add-outline"></ion-icon>
				</ion-fab-button>
			</ion-fab>
		</ion-content>
	</ion-page>
</template>

<script>
	//Aux
	import {sincronizaClientes, toast} from "@/helpers";

	const sha1 = require('sha1');

	//Componentes Personalizados
	import VueUploadComponent from 'vue-upload-component';

	//Componentes Ionic
	import {
		IonPage,
		IonHeader,
		IonToolbar,
		IonButtons,
		IonMenuButton,
		IonContent,
		IonTitle,
		IonIcon,
		IonFabButton,
		IonFab,
		IonNote,
		IonSpinner,
		IonInput,
		IonLabel,
		IonToggle,
		IonItem,
		IonButton,
		IonList,
		IonItemDivider
	} from "@ionic/vue";

	//Ícones Ionic
	import {
		addOutline,
		cloudUploadOutline
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"add-outline": addOutline,
		"cloud-upload-outline": cloudUploadOutline,
	});

	//Mixins
	import TitleMixin from "@/mixins/TitleMixin";

	//Firebase
	import {db} from "@/firebase";

	//Vue
	import {ref} from "vue";

	export default {
		name: "DashboardIndex",

		data() {
			return {
				updatingPassword: false,
				changePassword: false,
				newPassword: undefined
			}
		},

		mixins: [
			TitleMixin
		],

		methods: {
			async updatePassword() {
				const vm = this;

				try {
					vm.updatingPassword = true;
					vm.$store.state.usuario.password = sha1(vm.newPassword);

					console.log(vm.newPassword, vm.$store.state.usuario);

					await db.collection('users')
						.doc(vm.$store.state.usuario.id)
						.update(vm.$store.state.usuario);

					toast("Senha atualizada com sucesso!");

				} catch (err) {
					toast("Erro ao tentar atualizar senha: " + err);

				} finally {
					vm.updatingPassword = false;
					vm.changePassword = false;
				}
			}
		},

		setup() {
			const upload = ref(null)
			const files = ref([]);
			const sincronizandoClientes = ref(false);
			const msgSync = ref("");
			const linkLog = ref("https://console.firebase.google.com/project/solicitacao-cotacao-fretes/functions/logs?hl=pt-br&functionFilter=sincronizaClientes(southamerica-east1)&search=&severity=DEBUG");

			function inputFile(newFile, oldFile) {
				if (newFile && !oldFile) {
					let myReader = new FileReader();
					myReader.addEventListener('load', function(event) {
						sincronizandoClientes.value = true;

						const data = event.target.result.replace(/(?:\r\n|\r|\n)/g, `\n`);

						sincronizaClientes({
							token: '5599f5092a604941ca6e835f82c00adf5c6a2597',
							data: data
						});

						msgSync.value = `Procedimento iniciado com sucesso em background! Confira o log para mais detalhes: <a href="${linkLog.value}" target="_blank">${linkLog.value}</a>`;

						sincronizandoClientes.value = false;
					});
					myReader.readAsText(newFile.file, 'UTF-8');//'ISO-8859-1');
				}
			}

			function inputFilter(newFile, oldFile, prevent) {
				if (newFile && !oldFile) {
					// Filter non-csv file
					if (!/\.(csv)$/i.test(newFile.name)) {
						toast("Extensão inválida. Permitido somente '.csv'!");
						return prevent()
					}
				}
			}

			return {
				files,
				upload,
				inputFilter,
				inputFile,
				sincronizandoClientes,
				msgSync,
				linkLog
			}
		},

		components: {
			IonPage,
			IonContent,
			IonTitle,
			IonToolbar,
			IonMenuButton,
			IonButtons,
			IonHeader,
			IonIcon,
			IonFabButton,
			IonFab,
			IonNote,
			IonSpinner,
			IonInput,
			IonLabel,
			IonToggle,
			IonItem,
			IonButton,
			IonList,
			IonItemDivider,
			'file-upload': VueUploadComponent
		}
	}
</script>

<style scoped>
	h3 {
		margin-top: 0;
	}

	#tip {
		position: absolute;
		right: 80px;
		bottom: 28px;
	}
</style>
